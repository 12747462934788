import React from "react";
import {Card, Typography, Box} from "@mui/material";

const style = {
	card: {
		margin: "auto 0px 0px 0px",
		width: "100%",
		height: "40px",
		borderRadius: "0px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		background: "rgba(96, 60, 186, 0.5)",
		boxShadow: "0px 2px 4px 4px rgba(0, 0, 0, 0.2)"
	},
	footerText: {
		fontWeight: "bold"
	}
};

function Footer(){
	return (
		<Box>
			<Card style={style.card}>
				<Typography style={style.footerText} variant="body1" color="textSecondary">
					By Sparkoder (Anuj Chowdhury)
				</Typography>
			</Card>
		</Box>
	);
}

export default Footer;