import React, {useState, useEffect} from "react";
import {Box, Card, Typography, IconButton, Icon} from "@mui/material";
import Marquee from "react-fast-marquee";

const style = {
	musicContainer: {
		margin: "5px auto 5px auto",
		width: "100%",
		maxWidth: "800px",
		padding: "0px",
		overflowY: "auto",
		borderRadius: "5px",
		background: "rgba(255, 255, 255, 0.1)",
		display: "flex",
		flexDirection: "column",
		flexGrow: "1",
		boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.1)"
	},
	card: {
		margin: "0px 0px 0px 0px",
		height: "70px",
		borderRadius: "0px",
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		background: "rgba(255, 255, 255, 0.3)",
		boxShadow: "none"
	},
	cardImage: {
		margin: "0px 0px 0px 15px",
		minWidth: "50px",
		width: "50px",
		height: "50px",
		objectFit: "cover",
		borderRadius: "10px",
		boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)"
	},
	musicNameContainer: {
		margin: "0px 15px 0px 15px",
		minWidth: "10px",
		width: "auto",
		display: "flex",
		flexGrow: "1"
	},
	playIconButton: {
		margin: "0px 15px 0px auto",
		padding: "10px",
		background: "#f472d0",
		boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)"
	},
	playIcon: {
		color: "#fff",
		fontVariationSettings: `
			'FILL' 1,
			'wght' 600,
			'GRAD' 0,
			'opsz' 24
		`
	},
	marquee: {
		zIndex: "0"
	},
	typography: {
		marginRight: "50px"
	}
};

function MusicContainer({musicContainerRef, songs, currentSong, setCurrentSong, setTabValue, setPlaying}){
	function handlePlay(index){
		setPlaying(true);

		currentSong.audio.currentTime = 0;

		currentSong.audio.pause();

		setCurrentSong(songs[index]);

		setTabValue(1);
	}

	return (
		<Box style={style.musicContainer} ref={musicContainerRef}>
			<Box>
				{
					songs.map((song, index) => {
						return (
							<Card style={style.card} key={index}>
								<img style={style.cardImage} src={song.thumbnail} draggable="false"/>

								<Box style={style.musicNameContainer}>
									<Marquee style={style.marquee}>
										<Typography style={style.typography} variant="body1">
											{song.name}
										</Typography>
									</Marquee>
								</Box>

								<IconButton style={style.playIconButton} onClick={() => {handlePlay(index)}}>
									<Icon style={style.playIcon} baseClassName="material-symbols-outlined">
										play_arrow
									</Icon>
								</IconButton>
							</Card>
						);
					})
				}
			</Box>
		</Box>
	);
}

export default MusicContainer;