import React, {useState, useRef} from "react";
import {Box, Card, Typography, TextField, Button} from "@mui/material";

const style = {
	box1: {
		margin: "30px auto 30px auto",
		width: "700px",
		maxWidth: "90%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexGrow: "1",
		borderRadius: "5px",
		boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.1)",
		overflowY: "auto"
	},
	card1: {
		width: "100%",
		height: "100%",
		background: "rgba(255, 255, 255, 0.3)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		boxShadow: "none",
		overflowY: "auto"
	},
	typography1: {
		margin: "30px 0px 0px 0px",
		fontWeight: "bold"
	},
	typography2: {
		margin: "20px 0px 0px 0px",
		fontWeight: "bold"
	},
	textField1: {
		margin: "0px 0px 20px 0px",
		padding: "15px 25px",
		borderRadius: "5px",
		backgroundColor: "rgba(50, 50, 50, 0.3)"
	},
	uploadButton1: {
		margin: "0px 0px 20px 0px",
		padding: "15px 25px",
		borderRadius: "5px",
		backgroundColor: "rgba(50, 50, 50, 0.3)",
		color: "#dbf2ff"
	},
	button1: {
		margin: "30px 0px 30px 0px",
		color: "#fff",
		fontWeight: "bold"
	}
};

function MusicImporter({songs, thumbnails, defaultThumbnail, setSongs, setTabValue}){
	const [songName, setSongName] = useState("");

	const [songAudio, setSongAudio] = useState("");

	const [songThumbnail, setSongThumbnail] = useState("");

	const songNameRef = useRef();

	const songAudioRef = useRef();

	const songAudioInputRef = useRef();

	const songThumbnailRef = useRef();

	const songThumbnailInputRef = useRef();

	function uploadAudio(event){
		setSongAudio(`${event.target.files[0].name.slice(0, 15)}...`);
	}

	function uploadThumbnail(event){
		setSongThumbnail(`${event.target.files[0].name.slice(0, 15)}...`);
	}

	function createSong(name){
		const audio = songAudioInputRef.current.files[0];

		const reader = new FileReader();

		reader.readAsDataURL(audio);

		reader.onload = async function(event){
			if (songThumbnailInputRef.current.files && songThumbnailInputRef.current.files[0]){
				const image = songThumbnailInputRef.current.files[0];

				const newReader = new FileReader();

				newReader.readAsDataURL(image);

				newReader.onload = async function(newEvent){
		            const song = {
		                number: songs.length,
		                audio: await new Audio(event.target.result),
		                name: name,
		                thumbnail: newEvent.target.result
		            };

		            setSongs((oldSongs) => {
		                const newSongs = [song, ...oldSongs];

		                newSongs.forEach((newSong, index) => {
		                	newSong.number = index;
		                });

		                return newSongs;
		            });
		        }
	        }
	        else{
	            const song = {
	                number: songs.length,
	                audio: await new Audio(event.target.result),
	                name: name,
	                thumbnail: defaultThumbnail
	            };

	            setSongs((oldSongs) => {
	                const newSongs = [song, ...oldSongs];

	                newSongs.forEach((newSong, index) => {
	                	newSong.number = index;
	                });

	                return newSongs;
	            });
	        }

	        setTabValue(0);
		}
	}

	function addSong(){
		if (songAudioInputRef.current.files && songAudioInputRef.current.files[0]){
			if (songName){
				createSong(songName);
			}
			else{
				createSong(songAudioInputRef.current.files[0].name);
			}
		}
	}

	return (
		<Box style={style.box1}>
			<Card style={style.card1}>
				<Typography style={style.typography1} variant="body1">
					Song Display Name
				</Typography>

				<TextField style={style.textField1} variant="standard" value={songName} onChange={(event) => setSongName(event.target.value)} ref={songNameRef}/>

				<Typography style={style.typography2} variant="body1">
					Song Audio
				</Typography>

				<Box className="uploadInput" style={style.uploadButton1} variant="standard" onClick={() => {songAudioInputRef.current.click()}} ref={songAudioRef}> Upload Audio </Box>

				<input style={{display: "none"}} type="file" accept="audio/*" ref={songAudioInputRef} onChange={uploadAudio}/>

				<Typography style={{maxWidth: "200px", height: "20px", margin: "-15px 0px 0px 0px"}} variant="body2"> {songAudio} </Typography>

				<Typography style={style.typography2} variant="body1">
					Song Thumbnail
				</Typography>

				<Box className="uploadInput" style={style.uploadButton1} variant="standard" onClick={() => {songThumbnailInputRef.current.click()}} ref={songThumbnailRef}> Upload Thumbnail </Box>

				<input style={{display: "none"}} type="file" accept="image/*" ref={songThumbnailInputRef} onChange={uploadThumbnail}/>

				<Typography style={{maxWidth: "200px", height: "20px", margin: "-15px 0px 0px 0px"}} variant="body2"> {songThumbnail} </Typography>

				<Button style={style.button1} variant="contained" color="secondary" onClick={addSong}> Add Song </Button>
			</Card>
		</Box>
	);
}

export default MusicImporter;