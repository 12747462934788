import React, {useState, useEffect, useRef} from "react";
import {Box, Card, Slider, IconButton, Icon, Typography} from "@mui/material";
import Marquee from "react-fast-marquee";

const style = {
	musicPlayer: {
		margin: "30px auto 30px auto",
		width: "600px",
		maxWidth: "90%",
		display: "flex",
		justifyContent: "center",
		flexGrow: "1",
		overflowY: "auto",
		borderRadius: "5px",
		boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.1)"
	},
	card: {
		width: "100%",
		height: "100%",
		background: "rgba(255, 255, 255, 0.3)",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		boxShadow: "none",
		overflowY: "auto"
	},
	imageContainer: {
		margin: "30px 0px 0px 0px",
		width: "auto",
		maxWidth: "80%",
		height: "100%",
		overflowY: "hidden"
	},
	cardImage: {
		margin: "0px",
		width: "100%",
		height: "100%",
		objectFit: "cover",
		borderRadius: "10px"
	},
	slider: {
		margin: "auto auto 0px auto",
		width: "80%"
	},
	playIconButton: {
		margin: "0px 10px 25px 10px",
		padding: "10px",
		background: "#f472d0",
		boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)"
	},
	playIconButtonSmall: {
		width: "50px",
		height: "50px",
		margin: "0px 5px 25px 5px",
		padding: "10px",
		background: "#f472d0",
		boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)"
	},
	playIconButtonSmaller: {
		width: "40px",
		height: "40px",
		margin: "-25px 20px 25px 20px",
		padding: "10px",
		background: "#f472d0",
		boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)"
	},
	playIconButtonSmallest: {
		width: "35px",
		height: "35px",
		margin: "0px 5px 25px 5px",
		padding: "10px",
		background: "#f472d0",
		boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)"
	},
	playIcon: {
		color: "#fff",
		fontSize: "50px",
		fontVariationSettings: `
			'FILL' 1,
			'wght' 600,
			'GRAD' 0,
			'opsz' 24
		`
	},
	playIconSmall: {
		color: "#fff",
		fontSize: "25px",
		fontVariationSettings: `
			'FILL' 1,
			'wght' 600,
			'GRAD' 0,
			'opsz' 24
		`
	},
	playIconSmaller: {
		color: "#fff",
		fontSize: "18px",
		fontVariationSettings: `
			'FILL' 1,
			'wght' 600,
			'GRAD' 0,
			'opsz' 24
		`
	},
	boxButtons: {
		margin: "0px",
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "row"
	},
	boxSmallButtons: {
		margin: "0px",
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "row"
	},
	musicNameContainer: {
		margin: "20px 100px 0px 100px",
		width: "auto",
		display: "flex",
		flexGrow: "1"
	},
	marquee: {
		zIndex: "0"
	},
	typography: {
		marginRight: "50px"
	}
};

function MusicPlayer({currentSong, setCurrentSong, playing, setPlaying, loopState, setLoopState, songs}){
	const [smallScreen, setSmallScreen] = useState(false);

	const playIconRef = useRef();

	const loopIconRef = useRef();

	const [sliderValue, setSliderValue] = useState(0);

	function handleSliderChange(event){
		const value = event.target.value;

		const time = ((currentSong.audio.duration / 100) * value);

		setSliderValue(value);

		currentSong.audio.currentTime = time;
	}

	function handlePlayClick(){
		if (currentSong.audio.paused){
			currentSong.audio.play();

			playIconRef.current.innerText = "pause";

			setPlaying(true);
		}
		else{
			currentSong.audio.pause();

			playIconRef.current.innerText = "play_arrow";

			setPlaying(false);
		}
	}

	function handleRewindClick(){
		currentSong.audio.currentTime -= 10;
	}

	function handleForwardClick(){
		currentSong.audio.currentTime += 10;
	}

	function handleLoopClick(){
        if (loopState === "loop"){
        	setLoopState("loopOne");

        	loopIconRef.current.innerText = "repeat_one";
        }
        else if (loopState === "loopOne"){
        	setLoopState("shuffle");

        	loopIconRef.current.innerText = "shuffle";
        }
        else if (loopState === "shuffle"){
        	setLoopState("stop");

        	loopIconRef.current.innerText = "stop_circle";
        }
        else{
        	setLoopState("loop");

        	loopIconRef.current.innerText = "repeat";
        }
	}

	function handleNextClick(){
        currentSong.audio.currentTime = 0;

        currentSong.audio.pause();

        if (songs[currentSong.number + 1]){
            setCurrentSong(songs[currentSong.number + 1]);
        }
        else{
            setCurrentSong(songs[0]);
        }
	}

	useEffect(() => {
		if (currentSong.audio.paused){
			currentSong.audio.loop = false;

			currentSong.audio.play();

			setPlaying(true);

			if (!playing){
				currentSong.audio.pause();

				playIconRef.current.innerText = "play_arrow";

				setPlaying(false);
			}
		}

		function changeValue(){
			const value = ((currentSong.audio.currentTime / currentSong.audio.duration) * 100);

			setSliderValue(value);
		}

		changeValue();

		const songInterval = setInterval(changeValue, 500);

		return () => {
			clearInterval(songInterval);
		}
	}, [currentSong]);

	useEffect(() => {
		if (playing){
			playIconRef.current.innerText = "pause";
		}
		else{
			playIconRef.current.innerText = "play_arrow";
		}
	}, [playing]);

	useEffect(() => {
		function resize(){
			if (window.innerWidth < 300){
				setSmallScreen(true);
			}
			else{
				setSmallScreen(false);
			}
		}

		window.addEventListener("resize", resize);

		resize();

        if (loopState === "loop"){
        	setLoopState("loop");

        	loopIconRef.current.innerText = "repeat";
        }
        else if (loopState === "loopOne"){
        	setLoopState("loopOne");

        	loopIconRef.current.innerText = "repeat_one";
        }
        else if (loopState === "shuffle"){
        	setLoopState("shuffle");

        	loopIconRef.current.innerText = "shuffle";
        }
        else{
        	setLoopState("stop");

        	loopIconRef.current.innerText = "stop_circle";
        }
	}, []);

	return (
		<Box style={style.musicPlayer}>
			<Card style={style.card}>
				<Box className="imageContainer" style={style.imageContainer}>
					<img style={style.cardImage} src={currentSong.thumbnail} draggable="false"/>
				</Box>

				<Box style={style.musicNameContainer}>
					<Marquee style={style.marquee}>
						<Typography style={style.typography} variant="body1">
							{currentSong.name}
						</Typography>
					</Marquee>
				</Box>

				<Slider value={sliderValue} valueLabelDisplay="off" style={style.slider} color="secondary" onChange={handleSliderChange}/>

				<Box style={style.boxButtons}>
					<IconButton style={style.playIconButtonSmallest} onClick={handleLoopClick}>
						<Icon style={style.playIconSmaller} baseClassName="material-symbols-outlined" ref={loopIconRef}>
							repeat
						</Icon>
					</IconButton>

					<IconButton style={style.playIconButtonSmall} onClick={handleRewindClick}>
						<Icon style={style.playIconSmall} baseClassName="material-symbols-outlined">
							fast_rewind
						</Icon>
					</IconButton>

					<IconButton style={style.playIconButton} onClick={handlePlayClick}>
						<Icon style={style.playIcon} baseClassName="material-symbols-outlined" ref={playIconRef}>
							play_arrow
						</Icon>
					</IconButton>

					<IconButton style={style.playIconButtonSmall} onClick={handleForwardClick}>
						<Icon style={style.playIconSmall} baseClassName="material-symbols-outlined">
							fast_forward
						</Icon>
					</IconButton>

					<IconButton style={style.playIconButtonSmallest} onClick={handleNextClick}>
						<Icon style={style.playIconSmaller} baseClassName="material-symbols-outlined">
							skip_next
						</Icon>
					</IconButton>
				</Box>
			</Card>
		</Box>
	);
}

export default MusicPlayer;