import React, {useState} from "react";
import {AppBar, Toolbar, Typography, Icon, Tabs, Tab} from "@mui/material";

const style = {
	appBar: {
		background: "rgba(96, 60, 186, 0.5)"
	},
	toolbar: {
		borderBottom: "0px solid #7c11f8",
	},
	headerIcon: {
		margin: "5px 0px 3px 0px",
		fontSize: "32px",
		color: "#fff",
		fontVariationSettings: `
			'FILL' 0,
			'wght' 600,
			'GRAD' 0,
			'opsz' 24
		`
	},
	headerTitle: {
		margin: "5px 0px 0px 0px",
		fontWeight: "bold",
		color: "#fff"
	},
	tab: {
		width: "33.33%",
		maxWidth: "33.33%",
		fontWeight: "bold"
	}
};

function Header({tabValue, setTabValue}){
	function switchTab(event, value){
		setTabValue(value);
	}

	return (
		<AppBar style={style.appBar} position="static">
			<Toolbar style={style.toolbar}>
				<Icon style={style.headerIcon} baseClassName="material-symbols-outlined">
					music_note
				</Icon>

				<Typography style={style.headerTitle} variant="h5">
					Limusic
				</Typography>
			</Toolbar>
		
			<Tabs value={tabValue} onChange={switchTab}>
				<Tab style={style.tab} label="Your Music"/>

				<Tab style={style.tab} label="Now Playing"/>

				<Tab style={style.tab} label="Add Music"/>
			</Tabs>
		</AppBar>
	);
}

export default Header;